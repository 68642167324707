import * as React from "react"
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import "./styles.css";
import styled from 'styled-components';
import TeamHero from '../images/team.jpg';
import TedHeadshot from '../images/ted-headshot.png';
import JillHeadshot from '../images/jill-headshot.png';
import Thomas from '../images/Thomas.png';
import Alexandria from '../images/Alexandria.png';
import Kassa from '../images/Kassa.png';
import Kelly from '../images/Kelly.png';
import Bobbie from '../images/Bobbie.png';
import Eric from '../images/Eric.png';
import Sal from '../images/sal.png';
import Alyssa from '../images/Alyssa.png';
import { Helmet } from "react-helmet";
import Testimonials from '../components/Testimonials';

const HeroImg = styled.img`
  height: 500px;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const TitleBox = styled.div`
    background: #e0e0e0;
    position: relative;
    top: -100px;
    max-width: 500px;
    height: 200px;
    opacity: 90%;

    @media screen and (max-width: 600px) {
      max-width: 75%;
      height: 150px;
    }
`;

const Heading = styled.h1`
  padding:60px 40px 40px 0;
  font-size:50px;
  text-align: center;
  font-family: 'Vollkorn', serif;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size:40px;
  }
`;

const BioSection = styled.div`
display: grid;
  width: 100%;
  margin-top: -100px;
  color: black;
  padding-bottom: 40px;

h2, h3 {
  font-family: 'Vollkorn', serif;
  margin-bottom: 0;
}

p{
  font-family: 'Poppins', sans-serif;
}
`;



const HeadshotImg = styled.img`
  max-width: 300px;
  margin: 0 auto;
  display: block;

  @media screen and (max-width: 600px) {
    max-width: 200px;
  }
`;

const Container = styled.div`
max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

`;

const Bio = styled.div`
display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 40px;
  max-width: 500px;
  text-align: center;
  
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
  h2 {
    color: rgb(89,129,144);
  }
`;

const TeamPage = () => {
  return (
    <main>
      <Helmet>
      <title>Our Team</title>
      <meta name="description" content="Dunning Currie Insurance Inc. is an independently owned Canadian Property &amp; Casualty premier insurance brokerage. " />
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta charSet="utf-8" />
      </Helmet>
      <Navigation />
      <div>
         <HeroImg src={TeamHero} alt="hero image" />
          <TitleBox><Heading>Our Team</Heading></TitleBox>
      </div>
      <BioSection>
        <Container>
        <Bio>
            <HeadshotImg src={JillHeadshot} alt="Jill Dunning" />
            <h2>Jill Dunning</h2>
          <h3>Managing Partner</h3>
          <p>With an impeccable reputation in the insurance community, Jill is driven by finding the best solutions for her clients.  She continually advocates for her clients ensuring they are protected and confident in their insurance decisions. Jill has over 20 years’ experience as an insurance broker allowing her to be the trusted advisor her clients depend&nbsp;on.</p>
          <p>Outside of the office, Jill can be found either on the slopes or on the golf&nbsp;course.</p>
          </Bio>
          <Bio>
            <HeadshotImg src={TedHeadshot} alt="Ted Currie" />
            <h2>Ted Currie</h2>
          <h3>Managing Partner</h3>
          <p>With over 11 years of experience in the property and casualty insurance industry, Ted Currie is a passionate broker that values advocating for his clients and building strong relationships with best-in-class insurance carrier partners.</p>
          <p>Ted is a creative broker that enjoys thinking outside of the box and prides himself in finding tailored solutions to insure the items that matter most to his clients.</p>
          <p>Outside of the office Ted is a proud family man that enjoys golf, hockey, skiing and being disappointed annually by the Toronto Maple&nbsp;Leafs!</p>
          </Bio>

        </Container>
        <Container>
        <Bio>
            <HeadshotImg src={Kassa} alt="Kassa" />
            <h2>Kassa Kebede</h2>
          <h3>Vice President</h3>
          <p>Passionate about helping clients protect their businesses and providing exceptional service, Kassa is an experienced Insurance Broker with a strong background in risk management. His expertise extends across various sectors throughout Canada, where he excels in finding tailored insurance solutions that safeguard clients' assets and effectively mitigate potential&nbsp;risks.</p>
          <p>Building teams is one of Kassa's passions. He firmly believes in the power of collaboration and understands the strength that emerges from working together towards a shared objective. By fostering an environment of trust, empowerment, and professional development, Kassa has successfully nurtured talented individuals, enabling them to excel in their roles and deliver exceptional service to their&nbsp;clients.</p>
          <p>When Kassa's not working, he enjoys reading, working out, and being outside.</p>
          </Bio>
        <Bio>
            <HeadshotImg src={Thomas} alt="Thomas" />
            <h2>Thomas Boland</h2>
          <h3>Account Executive</h3>
          <p>Thomas is a creative broker who prides himself on being able to provide tailored solutions with top-of-the-line service and communication in the property and casualty insurance industry.</p>
          <p>He has a passion for creating and maintaining relationships, and looks forward to helping businesses and individuals protect the things that they value.</p>
          <p>Thomas graduated from Dalhousie University with a degree in Commerce. Outside of the office you can find him skiing, playing golf and spending his summers on Georgian bay.</p>
          </Bio>
        </Container>
        <Container>
        <Bio>
            <HeadshotImg src={Kelly} alt="Kelly" />
            <h2>Kelly Woods</h2>
          <h3>Account Manager</h3>
          <p>Kelly's enthusiastic approach, coupled with over 16 years&apos; experience in personal and casualty lines, brings value to our customers.  Kelly prides herself on providing an excellent customer first philosophy, that our clients can trust.</p>
          <p>Outside of the office, Kelly is a busy mother of two who enjoys sporting events with her family</p>
          </Bio>
          <Bio>
            <HeadshotImg src={Eric} alt="Eric" />
            <h2>Eric Currie</h2>
          <h3>Account Executive</h3>
          <p>With almost a decade of experience under his belt, Eric has developed a deep understanding of the intricacies of insurance, empowering him to deliver tailored solutions to his clients.</p>
          <p>Eric thrives on ensuring his clients have the coverage they need to navigate life's unexpected challenges. Outside of working hours, Eric is an outdoor enthusiast with a passion for travel.</p>
          </Bio>
        </Container>
        <Container>
        <Bio>
            <HeadshotImg src={Alexandria} alt="Alexandria" />
            <h2>Alexandria Cumming</h2>
          <h3>Commercial Technical Service Rep</h3>
          <p>With over a decade in the service industry, Ally is known for her bright outlook and enthusiasm in all she does.  She takes the time to understand the needs of her clients and is willing to go above and beyond to ensure their concerns are addressed and resolved.  Ally’s clients always feel valued.</p>
          <p>Outside the office, Ally can be found tending her vegetable garden, hiking, reading, or spoiling her cats.</p>
          </Bio>
          <Bio>
            <HeadshotImg src={Bobbie} alt="Bobbie" />
            <h2>Bobbie Sherwood</h2>
          <h3>Account Executive</h3>
          <p>Bobbie has built a solid reputation for delivering exceptional client service and providing customized insurance solutions.</p>
          <p>By taking a personalized approach to every client, he ensures that individuals and businesses have the right protection in place to navigate unforeseen circumstances and safeguard their financial well-being.</p>
          <p>As a trusted advisor Bobbies approach goes beyond insurance coverage as he strives to build long-lasting partnerships, offering ongoing support, guidance, and dedicated claims assistance.</p>
          <p>Beyond insurance Bobbie enjoys hockey, golf, and camping!</p>
          </Bio>
        </Container>
        <Container>
        <Bio>
            <HeadshotImg src={Sal} alt="Sal" />
            <h2>Sal Malak</h2>
          <h3>Account Executive</h3>
          <p>Meet Sal Malak, a seasoned professional with over five years of expertise in property and casualty insurance. Beyond his proficiency in the industry, Sal is a dedicated relationship builder and risk management expert, ensuring his clients receive tailored solutions and peace of mind. But when he's not immersed in the world of insurance, you'll find Sal hitting the open road on his motorcycle, embracing the freedom of the ride. A passionate photographer in his spare time, Sal captures life's moments with a keen eye and creative flair. With Sal Malak, you not only gain a trusted insurance advisor but also a fellow adventurer who knows how to seize the moments worth protecting.</p>
          </Bio>
          <Bio>
            <HeadshotImg src={Alyssa} alt="Alyssa" />
            <h2>Alyssa Simon</h2>
          <h3>Account Executive</h3>
          <p>Welcome to my corner of Dunning Currie Insurance Inc.! With over 15 years of customer service experience, I am dedicated to making the insurance shopping process a breeze for my clients. As an Account Executive, my goal is to not only provide top-notch service but also to connect with each customer on a personal level.</p>
          <p>When I'm not helping clients find the perfect insurance coverage, you can find me chasing after my energetic toddler or getting involved in various community activities. I believe in the power of building relationships, both in my personal life and in my professional career.
          <p>I look forward to working with you and making the insurance process more enjoyable for you. Let's connect and find the perfect coverage for your needs!</p>
          </p>
          </Bio>
        </Container>
        </BioSection>
        <Testimonials />
      <Footer />
    </main>
  )
}

export default TeamPage

export const Head = () => <title>Our Team</title>
